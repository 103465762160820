import React from "react";
import { Col, Row } from "react-bootstrap";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { Elements } from "@stripe/react-stripe-js";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import { Button } from "react-bootstrap";

import { loadStripe } from "@stripe/stripe-js/pure";

const { NODE_ENV } = process.env;

const STRIPE_PUBLISHABLE_KEY =
  NODE_ENV === "production"
    ? "pk_live_pcRrShNSd8yDbIZ4r3IPaP6r"
    : "pk_test_UensMVDYJYxNl2S3fTpJJ5Ew";
const BACKEND_URL =
  NODE_ENV === "production"
    ? "https://app.taplist.io/api/v1/plan/start-gift-card-purchase"
    : "https://app-staging.taplist.io/api/v1/plan/start-gift-card-purchase";

let stripePromise;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);
  }
  return stripePromise;
};

const StripeContainer = ({ children }) => (
  <Elements stripe={getStripe()}>{children}</Elements>
);

const CheckoutButton = () => {
  const handleClick = async event => {
    const stripe = await getStripe();
    const response = await fetch(BACKEND_URL, {
      method: "POST",
    });

    const session = await response.json();

    // When the customer clicks on the button, redirect them to Checkout.
    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    });

    if (result.error) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
    }
  };

  return (
    <Button size="lg" onClick={handleClick}>
      Purchase a Gift Card
    </Button>
  );
};

const GiftCardsPage = () => {
  const data = useStaticQuery(graphql`
    query {
      giftCards: file(relativePath: { eq: "gift-cards-01.png" }) {
        childImageSharp {
          # Specify a fixed image and fragment.
          # The default width is 400 pixels
          fluid(maxWidth: 800, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <SEO title="Purchase official gift cards for Taplist.io" />
      <section className="section" style={{ paddingTop: 0 }}>
        <Row>
          <Col className="text-center">
            <h1>Taplist.io Gift Cards</h1>
          </Col>
        </Row>
        <Row>
          <Col span={{ md: 8, offset: 2 }}>
            <Img
              fluid={data.giftCards.childImageSharp.fluid}
              alt="Several Taplist.io gift cards"
            />
            <p className="lead text-center" style={{ marginTop: '1.0em' }}>
              We are pleased to now offer gift cards for Homebrewers!
            </p>
            <p>
              A Taplist.io Gift Card makes an easy and unique gift. When you
              purchase a gift card, whoever receives it can use it as a credit
              during sign up. Currently we offer a <b>$39 Gift Card</b>&mdash;which
              will cover a <b>1 Year Hobby Subscription</b>, our most popular plan
              for Homebrewers.
            </p>
            <p className="text-center">
              <StripeContainer>
                <CheckoutButton />
              </StripeContainer>
            </p>
            <h4>How to use</h4>
            <p>
              Gift cards are delivered digitally to your email address.
              We send you a pickup URL with a nice, downloadable image.
              All you need is the "code" portion, so you can share the gift
              card with your recipient any way you like: Through your own
              personalized email, print it out and put it in a physical
              card, and so on.
            </p>
            <h4>Questions?</h4>
            <p>
              We're a small shop that's <i>loved</i> by our customers.
              That also means we're happy to have your questions! Shoot
              us a note at <a href="mailto:support@taplist.io">support@taplist.io</a>{' '}
              and we'll get right back to you.
            </p>
            <h4>The Fine Print</h4>
            <p>
              Gift codes work like cash, so please don't lose it once we send
              it to you. Gift cards are non-refundable 30 days after purchase.
            </p>
          </Col>
        </Row>
      </section>
    </Layout>
  );
};

export default GiftCardsPage;
